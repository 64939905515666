body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

footer {
  text-align: center;
  padding: 10px;
  font: 'Roboto';
  font-size: 4;
}

.custom-link {
  color: black;
  font-size: 14px;
  text-decoration: underline;
}

.custom-link-mail {
  color: black;
  font-size: 14px;
  text-decoration: none;
}

.custom-link:hover {
  color: "#003c50";
}

.small-text {
  font-size: 14px; 
  color: black; 
  text-decoration: none;
}