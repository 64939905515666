/* Root styling */
:root {
    --toastify-icon-color-success: #097509 !important;
    --toastify-color-progress-success: #097509 !important;
    --toastify-icon-color-error: #A80000 !important;
    --toastify-color-progress-error: #A80000 !important;
    --toastify-icon-color-info: #003c50 !important;
    --toastify-color-progress-info: #003c50 !important;
    --toastify-toast-width: 400px !important;
}

/* Style for success toast */
.Toastify__toast--success {
    background: #DFF6DD !important;
    color: #097509 !important;
    font-family: 'Roboto' !important;
    font-size: 16px;

}

/* Style for error toast */
.Toastify__toast--error {
    background: #FDE7E9 !important;
    color: #A80000 !important;
    font-family: 'Roboto' !important;
    font-size: 16px;
}

/* Style for info toast */
.Toastify__toast--info {
    background: #ffffff !important;
    color: #003c50 !important;
    font-family: 'Roboto';
    font-size: 16px;
}