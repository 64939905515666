.chart-container {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: flex-end; /* Align children to the end (right side) */
}

.dropdown-container {
    margin-bottom: 30px; /* Space between dropdown and chart */
}

.custom-dropdown {
    width: 120px; /* Adjust width as needed */
    height: 40px; /* Adjust height as needed */
    padding: 10px; /* Inner padding */
    font-size: 16px; /* Text size */
    margin-top: 1px;
    margin-right: 30px;
    border: 2px solid #75b3b3;
    border-radius: 5px; /* Rounded corners */
    background-color: #ffffff; /* Background color */
    cursor: pointer;
    transition: border-color 0.3s; /* Transition for border color */
}

.custom-dropdown:hover {
    border-color: #4f9c9c; /* Change border color on hover */
}

.custom-dropdown:focus {
    outline: none; /* Remove default outline */
    border-color: #4f9c9c; /* Highlight border when focused */
}
