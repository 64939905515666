.header {
    margin-top: 0;
    margin-right: 20px;
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
    font-family: 'Roboto';
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}

.line {
    width: 100%; 
    text-align: center;
    margin-bottom: 15px;
    margin-top: 15px;
    border: 1px solid rgba(0, 0, 0, .125);
}