.ag-theme-alpine .ag-cell {
    line-height: 1.5; /* Change line height */
    padding: 5px; /* Adjust padding */
}

.ag-theme-alpine .ag-cell p {
    margin: 0; /* Remove default paragraph margins */
}

.ag-theme-alpine .ag-cell br {
    line-height: inherit; /* Control line breaks */
}
